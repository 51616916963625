'use client';
import React from 'react';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { Arrow } from '@radix-ui/react-dropdown-menu';
import { ArrowBigDown, ArrowLeft, ChevronLeft } from 'lucide-react';
import { Button } from '@/components/ui/button';

function BreadCrumbList() {
    const pathname = usePathname();
    const pathSegments = pathname.split('/').filter(Boolean);
    const router = useRouter()

    const handleGoBack = () => {
        router.back()
    }

    return (
        <nav aria-label="breadcrumb">

            <ol className="flex mx-5 space-x-1 text-xs flex-wrap items-center">
                <li className='mr-2'>
                    <div onClick={handleGoBack} className="hover:underline flex items-center space-x-1">
                        <Button variant="outline" size="icon" className="h-7 w-7">
                            <ChevronLeft className="h-4 w-4" />
                            <span className="sr-only">Back</span>
                        </Button>                   
                    </div>
                </li>
                <li>
                    <Link prefetch={false} href="/dashboard" className="hover:underline">
                        Home
                    </Link>
                </li>
                {pathSegments.map((segment, index) => {
                    const href = '/' + pathSegments.slice(0, index + 1).join('/');
                    const isLast = index === pathSegments.length - 1;
                    return (
                        <React.Fragment key={segment}>
                            <li className="mx-4">/</li>
                            <li className="mx-4">
                                {isLast ? (
                                    <span className="text-primary">{segment}</span>
                                ) : (
                                    <Link prefetch={false} href={href} className="hover:underline">
                                        {segment}
                                    </Link>
                                )}
                            </li>
                        </React.Fragment>
                    );
                })}
            </ol>
        </nav>
    )
}

export default BreadCrumbList